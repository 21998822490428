export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const FILTER_BY_TYPE = "FILTER_BY_TYPE";
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const COUNTER_PRODUCT = "COUNTER_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const SIGN_IN = "SIGN_IN";
export const LOGIN_USER = "LOGIN_USER";
export const DELETE_PRODUCT_DB = "DELETE_PRODUCT_DB";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_PRODUCT = "GET_CATEGORY_PRODUCT";
export const GET_CREATE_ORDER = "GET_CREATE_ORDER";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_PRODUCTS_PER_PAGE = "SET_PRODUCTS_PER_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const SHOW_PRODUCT_ADDED_POPUP = "SHOW_PRODUCT_ADDED_POPUP";
export const GET_FILTER_SKU = "GET_FILTER_SKU";
export const GET_OFFERT = "GET_OFFERT";
export const GET_ALL_PAYMENTS = " GET_ALL_PAYMENTS";
