import React, { useState, useEffect } from "react";
import "./index.css";

export const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      text: "¡Excelente servicio! La compra fue fácil y el producto es increíble.",
      author: "Juan Pérez",
      image: "",
    },
    {
      id: 2,
      text: "Muy satisfecha con mi compra. Llegó rápido y en perfecto estado.",
      author: "Ana Gómez",
      image: "",
    },
    {
      id: 3,
      text: "Gran variedad de productos y precios razonables. Recomiendo esta tienda.",
      author: "Luis Rodríguez",
      image: "",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((current) => (current + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div className="testimonials-container">
      <div className="testimonial">
        <p className="testimonial-text">
          "{testimonials[currentTestimonial].text}"
        </p>
        <p className="testimonial-author">
          - {testimonials[currentTestimonial].author}
        </p>
      </div>
    </div>
  );
};
