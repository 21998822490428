import { FaWhatsapp } from "react-icons/fa";
import "./index.css";

export const WhatsAppLink = () => {
  const phoneNumber = "1528849705";
  const url = `https://wa.me/${phoneNumber}`;

  return (
    <div className="whatsapp-link-container">
      <a href={url} className="whatsapp-link">
        <FaWhatsapp />
        <span className="whatsapp-text">WhatsApp</span>
      </a>
    </div>
  );
};

export default WhatsAppLink;
