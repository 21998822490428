import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import {
  ProductDetailFunction,
  FilterByType,
  ProductDetailCarrousel,
  GetCategoryProduct,
  handleAddToCart,
  showProductAddedPopup,
} from "../../redux/actions/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "../Spinner/index";
import { FcApproval } from "react-icons/fc";
// import { TermsAndConditionsPopup } from "../TermsAndConditionsPopup/index";

import Swal from "sweetalert2";
import "./index.css";

export const ProductDetail = () => {
  let dispatch = useDispatch();

  const { loginWithRedirect } = useAuth0();
  const { id_product } = useParams();

  const {
    allProductsFilter,
    productDetail,
    allProducts,
    cart,
    isAuthenticated,
  } = useSelector((state) => state);

  const [isAdded, setIsAdded] = useState(false);
  console.log(isAuthenticated);
  // terms and conditions
  // const [termsAccepted, setTermsAccepted] = useState(
  //   localStorage.getItem("termsAccepted") === "true"
  // );
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  useEffect(() => {
    dispatch(ProductDetailFunction(id_product, allProductsFilter)).then(() =>
      setShowSpinner(false)
    );
    dispatch(FilterByType());
  }, [id_product]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  // const handleAcceptTerms = () => {
  //   setTermsAccepted(true);
  //   localStorage.setItem("termsAccepted", "true");
  //   setShowTermsPopup(false);
  // };

  const handleAdd = (detail) => {
    if (!isAuthenticated) {
      Swal.fire({
        icon: "error",
        title: "Autenticación Requerida",
        text: "Necesitas iniciar sesión para añadir artículos a tu carrito.",
        confirmButtonText: "Ir al Inicio de Sesión",
      }).then((result) => {
        if (result.isConfirmed) {
          loginWithRedirect();
        }
      });

      // } else if (!termsAccepted) {
      // setShowTermsPopup(true);
    } else {
      // El usuario está autenticado y ha aceptado los términos
      dispatch(handleAddToCart(productDetail, cart, isAuthenticated));
      setIsAdded(true);
      dispatch(showProductAddedPopup(productDetail));
      toast(
        <div>
          {detail.name} se ha añadido al carrito! <FcApproval />
        </div>
      );
    }
  };
  const formatPrice = (price) => {
    return price.toLocaleString("es-AR");
  };

  const handleRelationProduct = (productDetail) => {
    dispatch(ProductDetailCarrousel(productDetail));
  };

  return (
    <>
      <ToastContainer />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <>
          {/* {showTermsPopup && (
            <TermsAndConditionsPopup onAccept={handleAcceptTerms} />
          )} */}
          <div className="detail-container">
            <div className="image-container">
              <img
                className="product-image_detail"
                src={productDetail.thumbnail}
                alt={productDetail.name}
              />
            </div>
            <div className="info-container">
              <h2 className="product-title">{productDetail.name}</h2>
              <p className="product-description">{productDetail.description}</p>
              <p className="product-description">{productDetail.sku}</p>
              <div className="content-button_price">
                <div className="content-price">
                  {productDetail.offert ? (
                    <>
                      <div className="content-productPrice_offertPrice">
                        <p className="product-price original-price">
                          ${formatPrice(productDetail.price)}
                        </p>
                        <p className="product-price offert-price">
                          <span className="offert-price_product">
                            $ {formatPrice(productDetail.offertPrice)}
                          </span>
                          <span className="offert-percentage">23% OFF</span>
                        </p>
                      </div>
                    </>
                  ) : (
                    <p className="product-price">
                      ${formatPrice(productDetail.price)}
                    </p>
                  )}
                </div>

                <div>
                  <button
                    className="add-to-cart-button"
                    onClick={() => handleAdd(productDetail)}
                    disabled={isAdded}
                  >
                    Agregar al carrito
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="content-detail-title_products">
              <p className="detail-title_products">Productos relacionados</p>
            </div>
            <div className="scroll-container_detail">
              {allProducts.length > 0 &&
                allProducts.map(
                  (el, index) =>
                    el.categoryId === productDetail.categoryId && (
                      <div
                        key={index}
                        onClick={() => handleRelationProduct(el.id_Product)}
                        className="content-product_relation"
                      >
                        <div
                          className="scroll-item_detail"
                          key={index}
                          onClick={() =>
                            dispatch(GetCategoryProduct(el.id_Category))
                          }
                        >
                          <div className="banner-icon_detail">
                            <img src={el.thumbnail} alt={el.type} />
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
